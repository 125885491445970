<template>
    <header class="header" id="header">
        
        <div class="wrap-mobile">
            <div class="wrap-sandwich" id="sandwich" @click="openMenuMob()">
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div class="logo" @click="goto('#home')">
                <img src="../assets/images/encinterativa-logo.png" alt="">
            </div>
        </div>

        <div class="wrap-menu" id="wrap-menu-mob">

            <nav>
                <ul>
                    <li 
                        v-for="(item, key) in menuItems" 
                        :key="key"
                    >
                        <a 
                            :class="{ activeItem: $route.hash == item.linkActive }"
                            @click="goto(item.param);">
                            {{item.label}}
                        </a>
                    </li>
                </ul>
                <div class="phone only-mob">
                    <a href="tel:01137917204">
                        <b-icon icon="telephone" scale="1" variant="info"></b-icon> <strong>(11) 3791-7204</strong>
                    </a>
                </div>
            </nav>
            
            <div class="phone only-desk">
                <a href="tel:01137917204">
                    <b-icon icon="telephone" scale="1" variant="info"></b-icon> (11) 3791-7204
                </a>
            </div>
        </div>

        <button class="goToBack" id="goToBack" @click="goto('#home')"><b-icon icon="arrow-up" scale="1" variant="info"></b-icon></button>

    </header>
</template>

<script>
    import {
        scroller
    } from "vue-scrollto/src/scrollTo";

    export default {
        data() {
            return {
                activeItem : false,//'#home',    
                menuItems: [
                    {id: 1, label: 'Home',      param: '#home',         path: '/home/',         linkActive: '#home'        },
                    {id: 2, label: 'Clientes',  param: '#clientes',         path: '/clientes/',         linkActive: '#clientes'        },
                    {id: 3, label: 'Sobre',     param: '#sobre',        path: '/sobre/',        linkActive: '#sobre'       },
                    {id: 4, label: 'Serviços',  param: '#servicos',     path: '/servicos/',     linkActive: '#servicos'    },
                    {id: 5, label: 'Trabalhos', param: '#trabalhos',    path: '/trabalhos/',    linkActive: '#trabalhos'   },
                    {id: 6, label: 'Contatos',  param: '#contatos',     path: '/contatos/',     linkActive: '#contatos'    },
                ]
            }
        },
        created () {
            window.addEventListener('scroll', this.handleScroll);
            window.addEventListener('scroll', this.showBackTop);
        },
        destroyed () {
            window.removeEventListener('scroll', this.handleScroll);
            window.removeEventListener('scroll', this.showBackTop);
        },
        methods:{
            openMenuMob(){
                document.getElementById("sandwich").classList.toggle("motion-in");
                document.getElementById("header").classList.toggle("motion-in");

            },
            scrollIntoView(event) {
                event.preventDefault()
                const href = event.target.getAttribute('href')
                //console.log(event.target.getAttribute('href'))
                const el = href ? document.querySelector(href) : null
                if (el) {
                    this.$refs.content.scrollTop = el.offsetTop
                }
            },
            showBackTop(){
                const btn = document.querySelector('#goToBack');

                btn.classList.remove('active');

                if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100)
                    btn.classList.add('active')

            },
            goto(param) {
                
                if(document.getElementById("header").className != 'header'){
                    this.openMenuMob();
                }

                const firstScrollTo = scroller();
                    firstScrollTo(param, 500, {
                        offset: (window.innerWidth <= 768) ? - 80 : -document.getElementById('header').offsetHeight
                    });
                    this.activeMenu(param);

            },
            activeMenu(param) {
                if(param != this.$route.hash){
                    this.$router.push({
                        path: param
                    });
                }
                this.activeItem = param;
            },
            getPos(elmTop) {
                document.querySelector(elmTop).offsetTop
            },
            handleScroll (){
                let _this = this;
                const els = document.querySelectorAll('section')
                els.forEach((el) => {
                    const elTop = el.getBoundingClientRect().top
                    const elBottom = el.getBoundingClientRect().bottom
                    // if (elTop >= 0 || elBottom <= 0) {
                    // //this.isActive = false
                    // } 
                    if ( (elTop - document.querySelector("#header").offsetHeight - 100)  <= 0 && elBottom >= 0) {
                        _this.activeMenu("#"+el.getAttribute('id'))
                    } 
                })
				// document.addEventListener('scroll', function () {
                    

                // }, {
                //     passive: true
                // });

            },
        },
        mounted(){
            setTimeout(() => {
                this.getPos(this.$route.hash)

                if(!this.$route.hash){
                    this.$router.push({
                        path: this.activeItem
                    });
                } else {
                    this.goto(this.$route.hash);
                }
            }, 200);      
			
			this.handleScroll();
        }
}
</script>
<style scoped lang='scss'>
    @import "../assets/scss/global";
    header{
        background: $white;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        border-bottom: 1px solid rgba($color: $cor2, $alpha: .2);
        z-index: 10;
        min-height: $menuHeight;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .wrap-mobile{
            
            .wrap-sandwich{
                display: none;
            }
        }
        
        .logo{
            cursor: pointer;
            width: 100px;
        }

        .wrap-menu{
            width: 100%;
            min-height: $menuHeight;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }

        a{
            text-transform: uppercase;
            position: relative;
            font-size: 16px;
            color: $cor2;
            text-decoration: none;
        }
        
        
        nav{
            ul{
                display: flex;
                justify-content: space-between;
                align-items: center;
                li{
                    padding: 10px;
                    a{
                        text-transform: uppercase;
                        position: relative;
                        font-size: 16px;
                        color: $cor2;
                        text-decoration: none;
                        transition: color .5s linear;
                        &:after{
                            content: '';
                            position: absolute;
                            left: 0;
                            bottom: -45px;
                            height: 4px;
                            background: $cor1;
                            width: 0;//100%;
                            transition: width .5s ease;
                        }
                        &:hover,&.activeItem{
                            color: $cor1;
                            &:after{
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
        .phone{
            margin-left: 20px;
            border-left: 2px solid rgba($cor2, .4);
            padding-left: 20px;
            @media(max-width:$screenMD){
                margin-left: 14px;
                a{
                    font-weight: 900;
                }
            }
        }
        .goToBack{
            opacity: 0;
            transition: .5s ease;
            position: fixed;
            bottom: 20px;
            right: 20px;
            border: none;
            border-radius: 50%;
            padding: 10px 15px;
            cursor: pointer;
            svg{
                width: 1.2rem;
                height: 1.2rem;
                margin-top: 5px;
            }
            &:hover{
               background-color: $cor1;
            }
        }
        .active{
            opacity: 1;
            transition: .5s ease;
        }
        @media(max-width:$screenLG) {
            //transition: all .0s ease-in;
            background: $white;
            min-height: 60px;
            display: flex;
            flex-flow: column;
            align-items: flex-start;
            justify-content: center;
            color: $cor2;
            .wrap-mobile{
                padding: 20px 0;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .wrap-sandwich{
                    display: flex;
                    width: 45px;
                    height: 25px;
                    position: relative;
                    display: flex;
                    flex-flow: column;
                    align-items: center;
                    justify-content: center;
                    span,span:first-child,span:last-child{
                        background-color: $cor1;
                        transition: all .3s ease;
                    }
                    span{
                        position: absolute;
                        left: 0;
                        width: 100%;
                        height: 3px;
                        margin: auto 0;
                        &:first-child{
                            transform-origin: left top;
                            top: 0;
                        }
                        &:last-child{
                            bottom: 0;
                        }
                    }
                    &.motion-in{                    
                        span{
                            &:nth-child(2){
                                width: 0;
                            }
                            &:first-child{
                                transform: rotate(40deg);
                            }
                            &:last-child{
                                transform: rotate(-40deg) translate(0px, -10px)
                            }
                        }
                    }
                }
            }
            .wrap-menu{
                transition: all .5s ease-in-out;
                min-height: auto;
                align-items: flex-start;
                justify-content: flex-start;
                height: 0;
                overflow: hidden;
                nav{
                    ul{
                        margin: 20px 0;
                        flex-flow: column;
                        align-items: flex-start;
                        li{
                            a{
                                font-size: 24px;
                                text-align: left;
                                color: $white;
                                color: $cor2;
                                &:after{
                                    //width: 100%;
                                    bottom: 0px;
                                }
                                &:hover,
                                &:focus{
                                    bottom: 0;
                                }
                            }
                        }
                    }
                }                
            }
            &.motion-in{
                .wrap-menu{
                    height: 100vh;
                }
            }
        }
    }

</style>
